import { default as _91id_93f5C6OefrsZMeta } from "/opt/buildhome/repo/pages/blogs/[id].vue?macro=true";
import { default as indexXTNWI3ocnoMeta } from "/opt/buildhome/repo/pages/blogs/index.vue?macro=true";
import { default as hitungProductOM2hN8OItzMeta } from "/opt/buildhome/repo/pages/hitungProduct.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as kebijakanPrivasibzEkusZGDsMeta } from "/opt/buildhome/repo/pages/kebijakanPrivasi.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as tentangKami2kAmzw6zDoMeta } from "/opt/buildhome/repo/pages/tentangKami.vue?macro=true";
export default [
  {
    name: _91id_93f5C6OefrsZMeta?.name ?? "blogs-id",
    path: _91id_93f5C6OefrsZMeta?.path ?? "/blogs/:id()",
    meta: _91id_93f5C6OefrsZMeta || {},
    alias: _91id_93f5C6OefrsZMeta?.alias || [],
    redirect: _91id_93f5C6OefrsZMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/blogs/[id].vue").then(m => m.default || m)
  },
  {
    name: indexXTNWI3ocnoMeta?.name ?? "blogs",
    path: indexXTNWI3ocnoMeta?.path ?? "/blogs",
    meta: indexXTNWI3ocnoMeta || {},
    alias: indexXTNWI3ocnoMeta?.alias || [],
    redirect: indexXTNWI3ocnoMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/blogs/index.vue").then(m => m.default || m)
  },
  {
    name: hitungProductOM2hN8OItzMeta?.name ?? "hitungProduct",
    path: hitungProductOM2hN8OItzMeta?.path ?? "/hitungProduct",
    meta: hitungProductOM2hN8OItzMeta || {},
    alias: hitungProductOM2hN8OItzMeta?.alias || [],
    redirect: hitungProductOM2hN8OItzMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/hitungProduct.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: kebijakanPrivasibzEkusZGDsMeta?.name ?? "kebijakanPrivasi",
    path: kebijakanPrivasibzEkusZGDsMeta?.path ?? "/kebijakanPrivasi",
    meta: kebijakanPrivasibzEkusZGDsMeta || {},
    alias: kebijakanPrivasibzEkusZGDsMeta?.alias || [],
    redirect: kebijakanPrivasibzEkusZGDsMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/kebijakanPrivasi.vue").then(m => m.default || m)
  },
  {
    name: login4dfr6kRqBoMeta?.name ?? "login",
    path: login4dfr6kRqBoMeta?.path ?? "/login",
    meta: login4dfr6kRqBoMeta || {},
    alias: login4dfr6kRqBoMeta?.alias || [],
    redirect: login4dfr6kRqBoMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: tentangKami2kAmzw6zDoMeta?.name ?? "tentangKami",
    path: tentangKami2kAmzw6zDoMeta?.path ?? "/tentangKami",
    meta: tentangKami2kAmzw6zDoMeta || {},
    alias: tentangKami2kAmzw6zDoMeta?.alias || [],
    redirect: tentangKami2kAmzw6zDoMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/tentangKami.vue").then(m => m.default || m)
  }
]